<script>
import Horizontal from "./horizontal";
import ax from '../../helpers/restful/service';
import {
    teacherMainLayoutComputed,
} from "@/state/helpers";
import AlertModal from "@/components/alert-modal.vue";
import ConfirmModal from "@/components/confirm-modal.vue";

export default {
    components: {
        Horizontal,
        "alert-modal": AlertModal,
        "confirm-modal": ConfirmModal,
    },
    data() {
        return {
            uid: JSON.parse(localStorage.getItem('auth.currentUser')).uid || 0,
            course_id: null,
            currentUser: null,
            loaded: false,
            loaderHeight: window.innerHeight * 0.1,
            videoPanelHeight: window.innerHeight * 0.2,

            isExist: false,
            isPaid: false,
            registed_data: null,
            course_data: {},

            seeked: false,
            showSeekedVideoModal: false,

            showStudyNotYetModal: false,

            showSaveContentStudySuccessModal: false,

            showWarningExamTimingModal: false,

            showUnauthorizeModal: false,
        };
    },
    
    computed: {
        ...teacherMainLayoutComputed,
    },
    async mounted() {
        document.body.classList.add('authentication-bg');
        this.course_id = this.$route.query.course_id;
        this.uid = JSON.parse(localStorage.getItem('auth.currentUser')).uid;

        if(this.course_id && this.uid){
            const resp = await ax.post("/check-status-member", { uid: this.uid, course_id: this.course_id });
            console.log("resp:", resp);
            if (resp.status != 200) {
                alert("เกิดข้อผิดพลาด กรุณาดำเนินการใหม่อีกครั้ง");
                return;
            }

            this.isExist = resp.data.is_exist;
            this.isPaid = resp.data.is_paid;
            this.registed_data = resp.data;
            
            console.log("this.registed_data.content_study:", this.registed_data.content_study);

            if(this.isExist && this.isPaid && this.registed_data != null){
                const resp = await ax.post("/get-content-by-course-id", { course_id: this.course_id });
                console.log("resp:", resp);
                if (resp.status != 200) {
                    alert("เกิดข้อผิดพลาด กรุณาดำเนินการใหม่อีกครั้ง");
                    return;
                }
                this.course_data = resp.data.course_data;
                
                if(this.registed_data.content_study == null){
                    this.registed_data.content_study = [];
                    this.course_data.content_json.videos.map(() => this.registed_data.content_study.push(false));
                }

                this.loaded = true;
            } else {
                this.showUnauthorizeModal = true;
                return;
            }
        } else {
            this.showUnauthorizeModal = true;
            return;
        }
        this.videoPanelHeight = window.innerWidth * 9 / 16;

    },
    methods: {
        async saveContentStudy(){
            const resp = await ax.post("/update-course-content-study", { 
                uid: this.uid, 
                course_id: this.course_id,
                content_study: this.registed_data.content_study,
            });
            console.log("resp:", resp);
            if (resp.status != 200) {
                alert("เกิดข้อผิดพลาด กรุณาดำเนินการใหม่อีกครั้ง");
                return;
            }
            this.showSaveContentStudySuccessModal = true;
            return;
        },
        seekedVideo(e) {
            console.log(e);
            if(this.registed_data.content_study[e.target.id] == true){
                console.log("viewed");
                return;
            }
            this.seeked = true;
            this.showSeekedVideoModal = true;
            setTimeout(()=> this.reload(), 7000);
            return;
        },

        async endedVideo(e){
            console.log("endedVideo:", e);
            console.log("this.registed_data.content_study:",  this.registed_data.content_study);
            if(this.seeked){
                this.showSeekedVideoModal = true;
                setTimeout(()=> this.reload(), 7000);
                return;
            }

            this.registed_data.content_study[e.target.id] = true;

            await this.saveContentStudy();
        },
        playingVideo() {

        },
        timeUpdateVideo() {
        },
        goToTest(){
            if(this.registed_data.is_study_all_content == 1 || this.registed_data.content_study.every(e => e)){
                // this.$router.push({ path: '/course-exam' });
                this.showWarningExamTimingModal = true;
                return;
            }
            this.showStudyNotYetModal = true;
            return;
        },
        async startCourseExam(){
            await ax.post("/start-course-exam", { uid: this.uid, course_id: this.course_id });
            this.goTo('/course-exam?course_id='+this.course_id);
        },
        goTo(path){
            this.$router.push({ path: path });
            return;
        },
        reload(){
            window.location.reload();
            return;
        },
    },
};
</script>

<template>
    <div>
        <Horizontal :layout="layoutType" v-if="loaded">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="mb-3 text-center">{{ course_data.course_title }}</h4>

                            <h5 class="mb-3 text-start">เนื้อหาการอบรม</h5>
                            
                            <h5 v-if="course_data.content_link != null" class="mt-2">คำอธิบายการอบรม</h5>
                            <a v-if="course_data.content_link" :href="course_data.content_link" target="_blank">{{ course_data.content_link_text ?? 'กดที่นี่เพื่อไปยังลิงค์' }}</a>
                            
                            <h5 v-if="course_data.course_homework_link != null" class="mt-2">ลิงค์สำหรับส่งแบบฟอร์มการบ้าน</h5>
                            <a v-if="course_data.course_homework_link" :href="course_data.course_homework_link" target="_blank">{{ course_data.course_homework_link_text ?? 'กดที่นี่เพื่อไปยังลิงค์' }}</a>
                            
                            <h5 v-if="course_data.course_question_link != null" class="mt-2">ลิงค์สำหรับส่งแบบฟอร์มคำถาม</h5>
                            <a v-if="course_data.course_question_link" :href="course_data.course_question_link" target="_blank">{{ course_data.course_question_link_text ?? 'กดที่นี่เพื่อไปยังลิงค์' }}</a>

                            <h5 v-if="course_data.content_json.pdf.length > 0" class="mt-5">เอกสารประกอบการอบรม</h5>
                            <ul v-if="course_data.content_json.pdf.length > 0">
                                <li v-for="(p, i) in course_data.content_json.pdf" :key="i">
                                    กดเพื่อดาวน์โหลด เอกสาร <a :href="p.file_url" target="_blank">{{  p.file_name }}</a>
                                </li>
                            </ul>
                            
                            <h5 v-if="course_data.content_json.images.length > 0" class="mt-5">ภาพประกอบการอบรม</h5>
                            <div v-if="course_data.content_json.images.length > 0" class="row">
                                <div class="col-6" v-for="(p, i) in course_data.content_json.images" :key="i">
                                    <p>{{ p.file_name }}</p>
                                    <img class="img-fluid" :src="p.file_url"/>
                                </div>
                            </div>
                           
                            <h5 v-if="course_data.content_json.videos.length > 0" class="mt-5">วิดีโอประกอบการอบรม</h5>
                            <h4 v-if="course_data.content_json.videos.length > 0" class="mt-3 fs-4 text-danger">*** กรุณารับชมวิดีโอการสอนแต่ละคลิปจนจบ และกดปุ่มยืนยันการบันทึกข้อมูลที่แสดงขึ้นหลังจากจบคลิป</h4>
                            <h4 v-if="course_data.content_json.videos.length > 0" class="fs-4 text-danger">เมื่อชมวิดีโอจนครบแล้ว ท่านจะสามารถเข้าทำการทดสอบ และย้อนดูเนื้อหาได้ ***</h4>
                            <div v-if="course_data.content_json.videos.length > 0" class="row">
                                <div class="col-12" v-for="(p, i) in course_data.content_json.videos" :key="i">
                                    <h3 class="mt-5 fs-5 text-primary">{{ p.file_name }} <span v-if="registed_data.content_study[i]" class="text-success"><br/><br/><i class="fa fa-check"></i> ท่านได้รับชมแล้ว</span></h3>
                                    <video class="img-fluid" :src="p.file_url" 
                                        controls 
                                        controlsList="nodownload" 
                                        oncontextmenu="return false;" 
                                        @seeked="seekedVideo" 
                                        @playing="playingVideo"
                                        @timeupdate="timeUpdateVideo"
                                        @ended="endedVideo"
                                        :id="i"
                                    >
                                        <source :src="p.file_url" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>

                        <div class="card-body text-center d-block">
                            <b-form v-if="registed_data.test_score == null" @submit.prevent="goToTest()">
                                <b-button size="lg" type="submit" variant="success" class="w-lg" >ทำข้อสอบ</b-button>
                            </b-form>
                            <div class="container" v-if="loaded && registed_data.test_score != null">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <h2 class="text-center">ท่านได้ทำการทดสอบเรียบร้อยแล้ว คะแนนของท่าน คือ</h2>
                                                <h1 class="text-center">{{  registed_data.test_score }} / {{ course_data.exam_json.amount_test }} คะแนน</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Horizontal>

    <alert-modal :isShow="showSeekedVideoModal" title="ตรวจสอบพบการเลี่ยงระบบ"
            text="มีการเลื่อนเวลาบนวิดีโอเพื่อให้จบเร็วขึ้น เพื่อการอบรมที่เท่าเทียม ระบบจะทำการรีเฟรชหน้าจอ เพื่อเริ่มต้นใหม่อีกครั้ง" v-on:close="reload"></alert-modal>

    <alert-modal :isShow="showStudyNotYetModal" title="ยังศึกษา หรืออบรมไม่ผ่านเกณฑ์ที่กำหนด"
            text="แนวทางผ่านเกณฑ์ ได้แก่ เปิดดูไฟล์ PDF ทั้งหมด, ดูวิดีโอประกอบการอบรมโดยไม่เลื่อนเวลาจนจบแต่ละวิดีโอ" v-on:close="showStudyNotYetModal = false;"></alert-modal>
    
    <alert-modal :isShow="showSaveContentStudySuccessModal" title="บันทึกการศึกษาเนื้อหาสำเร็จ"
            text="บันทึกสำเร็จ" v-on:close="showSaveContentStudySuccessModal = false;"></alert-modal>
    
    <alert-modal :isShow="showUnauthorizeModal" title="คุณไม่ได้รับอนุญาตให้เข้าถึงเนื้อหา"
            text="ระบบจะส่งท่านกลับไปยังหน้าหลัก" v-on:close="goTo('/course-registed');"></alert-modal>
    
    <confirm-modal :isShow="showWarningExamTimingModal" title="เริ่มกระบวนการทดสอบ"
        :text="'หากกดยืนยัน การจับเวลาการทดสอบจากเริ่มต้นทันที คุณมีเวลาที่จะทำแบบทดสอบ '+(course_data.exam_json?.time_test || 0)+' นาที จำนวน '+(course_data.exam_json?.amount_test || 0)+' ข้อ'" v-on:close="showWarningExamTimingModal = false"
        v-on:confirm="startCourseExam"></confirm-modal>

</div></template>
